<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="140px" @submit.native.prevent>
            <el-form-item label="业务名：" prop="fieldName">
                <el-input v-model="inputForm.fieldName" placeholder="请输入字段名"></el-input>
            </el-form-item>
            <el-form-item label="业务排序：" prop="fieldSort">
                <el-input-number v-model="inputForm.fieldSort" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="是否发布：" prop="publishState">
                <el-switch v-model="inputForm.publishState" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    fieldName: '',
                    publishState: 0,
                    fieldSort: '',
                    masterTableId: ''
                },
                fieldNameOptions: [],
                dataRule: {
                    fieldName: [
                        {required: true, message: '业务字段名不能为空', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                if (method === 'add') {
                    this.title = `新增业务`
                    this.inputForm.masterTableId = row
                } else if (method === 'edit') {
                    this.title = '修改业务'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.title==`新增业务`){
                            this.$axios(this.api.original.businessFieldDesciptionSave, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message({
                                        message: '操作成功',
                                        type: 'success',
                                        duration: 1500
                                    })
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }else{
                            this.$axios(this.api.original.businessFieldDesciptionUpdateById, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message({
                                        message: '操作成功',
                                        type: 'success',
                                        duration: 1500
                                    })
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
